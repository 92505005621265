import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Tooltip from '../components/Tooltip'

import BackgroundImage from 'gatsby-background-image'

const StyledTitle = styled.div`
    width: 300px;
    text-align: center;
    font-family: Arial Black;
    color: rgba(2, 2, 2, 0.6);
    font-weight: 800;
`

const StyledTitleText = styled.div`
    text-align: center;
`
const Image = ({ img, className, onClick }) => (
    <BackgroundImage
        onClick={onClick}
        className={className}
        fluid={img}
        aria-label="Porntees"
        preserveStackingContext={true}
    />
)
const imagesNames = [
    'green',
    'red',
    'pink',
    'tanktop',
    'shortblack',
    'shortgreen',
    'longpink',
]
const Gallery = ({ className, x, y }) => {
    const images = useStaticQuery(
        graphql`
            query {
                green: file(relativePath: { eq: "longsleeves-green.jpg" }) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 4160) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                red: file(relativePath: { eq: "longsleeves-pink.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1400, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                pink: file(relativePath: { eq: "shortsleeves-red.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1400, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                tanktop: file(relativePath: { eq: "tanktop.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1200, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                shortblack: file(relativePath: { eq: "short-black.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1200, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                shortgreen: file(relativePath: { eq: "short-green.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1200, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                longpink: file(relativePath: { eq: "long-pink.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1200, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )
    const [index, setImageIndex] = useState(0)
    return (
        <>
            <Tooltip
                x={x}
                y={y}
                title={
                    <StyledTitle>
                        <StyledTitleText>{'PORNTEES'}</StyledTitleText>
                        <StyledTitleText>{'WWW COMING SOON'}</StyledTitleText>
                        <StyledTitleText>{`${index}/${imagesNames.length}`}</StyledTitleText>
                    </StyledTitle>
                }
            />
            <Image
                img={images[imagesNames[index]].childImageSharp.fluid}
                className={className}
                onClick={() =>
                    index >= imagesNames.length - 1
                        ? setImageIndex(0)
                        : setImageIndex(index + 1)
                }
            />
        </>
    )
}

export default styled(Gallery)`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`
