import React from 'react'

export default ({ x, y, title }) => {
    return (
        <div
            style={{
                background: 'transparent',
                padding: 10,
                left: `calc(${x}px - 30px)`,
                top: `calc(${y}px - 30px)`,
                position: 'absolute',
            }}>
            {title}
        </div>
    )
}
