import React from 'react'
import styled from 'styled-components'

const StyledForm = styled.div`
    background: #fff;
    position: fixed;
    z-index: 100;
    bottom: 0;
    margin: 0 0 30px 30px;
    padding: 0;
    font: 14px Helvetica, Arial, sans-serif;
`

const StyledInput = styled.input`
    width: 400px;
    padding: 10px;
    height: 50px;
    outline: 0;
`

const StyledSubmit = styled.input`
    width: 400px;
    height: 50px;
    outline: 0;
    background-color: white;
`

export default () => (
    <StyledForm id="mc_embed_signup">
        <form
            action="https://shop.us4.list-manage.com/subscribe/post?u=3c9158f8eceb6ada76b3bd5d3&amp;id=653ce7aef7"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            novalidate>
            <div id="mc_embed_signup_scroll">
                <StyledInput
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    placeholder="email address"
                    required
                />
                <div style={{ display: 'none' }} aria-hidden="true">
                    <input
                        type="text"
                        name="b_3c9158f8eceb6ada76b3bd5d3_653ce7aef7"
                        tabindex="-1"
                    />
                </div>
                <div>
                    <StyledSubmit
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                    />
                </div>
            </div>
        </form>
    </StyledForm>
)
