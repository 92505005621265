import React from 'react'

import Layout from '../components/layout'
import Gallery from '../components/Gallery'
import NewsletterForm from '../components/NewsletterForm'
import SEO from '../components/seo'
import useMousePosition from '@react-hook/mouse-position'

const IndexPage = () => {
    const [{ pageX, pageY }, ref] = useMousePosition(0, 0, 100)
    console.log(pageX, pageY)
    return (
        <div ref={ref}>
            <Layout>
                <SEO title="Porntees Coming Soon 2020" />
                <Gallery x={pageX} y={pageY} />
                <NewsletterForm />
            </Layout>
        </div>
    )
}

export default IndexPage
